const initialState = {
  company: {}
}

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_COMPANY":
      return { company: action.data }

    case "GET_COMPANY":
      return { company: action.data }

    case "UPDATE_COMPANY":
      return { company: action.data }

    default:
      return state
  }
}

export default companyReducer
