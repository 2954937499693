const initialState = {
  employees: [],
  selectedEmployee: {}
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_USER":
      return { employees: action.data }

    default:
      return state
  }
}

export default userReducer
