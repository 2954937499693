import { sortByKeyDes } from "../../../../../utility/Utils"

export const initialState = {
  contests: [],
  currentContest: {}
}

const contest = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_CONTESTS":
      return {
        ...state,
        contests: [...state.contests, { ...action.data }],
        currentContest: { ...action.data }
      }
    case "GET_CONTESTS":
      return { ...state, contests: action.data }
    case "SELECT_CURRENT_CONTEST":
      const newState = {
        ...state,
        currentContest: {
          ...state.currentContest
        }
      }
      newState.currentContest = { ...action.data }
      return newState

    case "UPDATE_METRIC":
      const copyState = {
        ...state,
        currentContest: {
          ...state.currentContest,
          contestants: [...state.currentContest.contestants]
        }
      }

      const updated = copyState.currentContest.contestants.filter(
        contestant => contestant.id === action.userId
      )

      action.data.emailDataDiff.forEach(email => updated[0].emails.push(email))
      action.data.callDataDiff.forEach(call => updated[0].calls.push(call))

      return copyState

    case "DELETE_CONTESTS":
      return { ...state, contests: action.contests }
    default:
      return state
  }
}

export default contest
