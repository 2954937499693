const initialState = {
  team: []
}

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TEAM":
      return { team: [...action.data] }

    default:
      return state
  }
}

export default teamReducer
